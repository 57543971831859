import {createBrowserRouter} from "react-router-dom";
import Home from "./views/home";
import * as React from "react";
import NoPermissionPage from "./views/api-doc";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/api-documentation",
        element: <NoPermissionPage/>,
    },
]);