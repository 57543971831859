import React from 'react';
import {Button, Layout} from 'antd';
import {HtmlFooter} from "../components/Footer";

const {Content} = Layout;

const EnterpriseAPIHomePage = () => {
    return (
        <Layout style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Content style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{marginBottom: '20px'}}>
                    <h1 style={{fontSize: '48px', marginBottom: '10px'}}>锋楪接口</h1>
                    <div style={{fontSize: '24px'}}>锋楪技术（深圳）有限公司接口中心</div>
                </div>
                <Button type="primary" size="large" href="/api-documentation"
                        style={{marginTop: '20px'}}>查看接口文档</Button>
            </Content>
            <HtmlFooter/>
        </Layout>
    );
};

export default EnterpriseAPIHomePage;

