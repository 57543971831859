import React from 'react';
import { Button, Layout } from 'antd';
import {HtmlFooter} from "../components/Footer";

const { Content} = Layout;

const NoPermissionPage = () => {
    new Date().getFullYear();
    return (
        <Layout style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Content style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{ marginBottom: '20px' }}>
                    <h1 style={{ fontSize: '48px', marginBottom: '10px' }}>此页面无权限</h1>
                    <div style={{ fontSize: '24px' }}>您没有权限访问该页面</div>
                </div>
                <Button type="primary" size="large" href="/" style={{ marginTop: '20px' }}>返回首页</Button>
            </Content>
            <HtmlFooter/>
        </Layout>
    );
};

export default NoPermissionPage;
