import React from "react";
import {Layout, Typography} from 'antd';

const {Link} = Typography;
const {Footer} = Layout;

export const HtmlFooter = () => {
    const year = new Date().getFullYear();
    return (
        <Footer style={{textAlign: 'center', backgroundColor: '#f0f2f5', padding: '20px', width: '100%'}}>
            <div style={{marginBottom: '10px'}}>
                <Link href="https://beian.miit.gov.cn/#/Integrated/index"
                      target="_blank">粤ICP备2023097009号</Link>
            </div>
            <div>© 2023-{year} 锋楪技术（深圳）有限公司 . All Rights Reserved. 版权所有</div>
        </Footer>
    )
}